import Vue from 'vue';
import $axios from './plugins/axios.js';
import App from './modules/MenuEditorModule.vue';
import AppLog from './modules/AppLog.vue';
// import './registerServiceWorker'

window.$app = {};
window.$app.modules = {};
window.$app.$refs = {};
window.$app.$store = window.$store || {};
window.$app.$store.messages = window.$app.$store.messages || [];

Vue.config.productionTip = false;
Vue.prototype.$axios = $axios;

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
});

//=========================================================
if (document.getElementById('bar-menu-editor')) {
  window.$app.modules.barMenuEditor = new Vue({
    //================================
    name: 'bar-menu-editor-app',
    //================================
    render: h => h(App),
    //================================
  })
  .$mount('#bar-menu-editor')
}
//=========================================================
if (document.getElementById('app-log')) {
  window.$app.modules.appLog = new Vue({
    //================================
    name: 'app-log-app',
    //================================
    data: window.$app.$store,
    //================================
    render: h => h(AppLog),
    //================================
  })
  .$mount('#app-log')
}
//=========================================================

