//
//

export default {
  //=====================================================
  name: 'applog',
  //=====================================================
  data() {
    return {
      visible: false,
      messages: this.$root.messages,
      showArchived: false,
      dateFormat: {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: 'America/Los_Angeles'
      }
    }
  },
  //=====================================================
  watch: {
    //===================
    count(newVal, oldVal) {
      if (newVal > oldVal) {
        this.open();
      }
    },
    //===================
  },
  //=====================================================
  computed: {
    //===================
    count() {
      return this.messages.length
    },
    //===================
    visibleMessages() {
      return this.messages
        .filter(m => {
          return this.showArchived ? true : !(m._archived)
        })
    },
    //===================
  },
  //=====================================================
  methods: {
    //===================
    log(message) {
      if (typeof message != 'object') {
        message = { message };
      }

      message.type = message.type || 'info';
      message.icon = message.icon || 'info';
      message.icon = message.type == 'warning' ? 'warning' : message.icon;
      message.icon = message.type == 'info' ? 'info' : message.icon;
      message.icon = message.type == 'success' ? 'check' : message.icon;
      message.icon = message.type == 'error' ? 'exclamation-triangle' : message.icon;
      message.date = message.date || new Intl.DateTimeFormat('default', this.dateFormat).format(new Date());

      this.messages.push(message);
    },
    //===================
    open() {
      console.log('[applog][open]');
      this.visible = true;
    },
    //===================
    close() {
      console.log('[applog][close]');
      this.visible = false;

      this.visibleMessages.forEach(m => {
        this.$set(m, '_archived', true)
      })
    },
    //===================
  },
  //=====================================================
  created() {
    console.log('[applog][created] $root:', this.$root);
  },
  //=====================================================
  mounted() {
    console.log('[applog][mounted]', this);
    window.$app.$refs.applog = this;
    this.messages.length ? this.open() : false;
  }
  //=====================================================
}
