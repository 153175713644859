var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "simple-editor Adm_Content",
      class: { fullscreen: _vm.fullscreen }
    },
    [
      _c(
        "datalist",
        { attrs: { id: "datalist-kategorie" } },
        _vm._l(_vm.categories, function(category, index) {
          return _c("option", {
            key: "cat-" + index,
            domProps: { value: category }
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row no-gutters mb-1" }, [
        _c("div", { staticClass: "col-md-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-10" }, [
          _c("div", { staticClass: "input-group input-group-sm" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { placeholder: "Szukaj..." },
              domProps: { value: _vm.search },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.search = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-light border",
                  on: {
                    click: function($event) {
                      _vm.search = ""
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-times" })]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light border",
              on: {
                click: function($event) {
                  _vm.fullscreen = !_vm.fullscreen
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-fw",
                class: _vm.fullscreen
                  ? "fa-window-minimize"
                  : "fa-window-maximize"
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light border",
              on: {
                click: function($event) {
                  return _vm.$emit("refresh")
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-fw fa-refresh",
                class: { "fa-spin": _vm.loading }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "j-table-wrapper" }, [
        _c("table", {}, [
          _c("thead", [
            _c(
              "tr",
              [
                _c("th", { staticClass: "px-1" }, [_vm._v("∑")]),
                _vm._v(" "),
                _vm._l(_vm.headers, function(header, index) {
                  return _c(
                    "th",
                    {
                      key: "col-" + index,
                      style: "width: " + header.width + "px;"
                    },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleHeaderClick(header)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(header.name) +
                              "\n              "
                          ),
                          _vm.sortBy == header.name && _vm.sortDesc == true
                            ? _c("small", [_vm._v("▲")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.sortBy == header.name && _vm.sortDesc == false
                            ? _c("small", [_vm._v("▼")])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "80px" } }, [_vm._v("Edytuj")])
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _c(
                "tr",
                { staticClass: "bg-success" },
                [
                  _c("td", { staticClass: "text-strong" }, [
                    _vm._v(_vm._s(_vm.rowsFiltered.length))
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.headers, function(header, colIndex) {
                    return _c(
                      "td",
                      { key: "add-col-" + colIndex },
                      [
                        header.id
                          ? [_vm._v("-")]
                          : header.type == "boolean"
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newRow[header.name],
                                    expression: "newRow[header.name]"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.newRow[header.name]
                                  )
                                    ? _vm._i(_vm.newRow[header.name], null) > -1
                                    : _vm.newRow[header.name]
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.newRow[header.name],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.newRow,
                                            header.name,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.newRow,
                                            header.name,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.newRow, header.name, $$c)
                                    }
                                  }
                                }
                              })
                            ]
                          : header.type == "number"
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newRow[header.name],
                                    expression: "newRow[header.name]"
                                  }
                                ],
                                staticClass: "admin-input text-right",
                                attrs: {
                                  type: "text",
                                  placeholder: header.name
                                },
                                domProps: { value: _vm.newRow[header.name] },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.saveNewRow()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.newRow,
                                      header.name,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          : [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newRow[header.name],
                                    expression: "newRow[header.name]"
                                  }
                                ],
                                staticClass: "admin-input",
                                attrs: {
                                  type: "text",
                                  placeholder: header.name,
                                  list: header.datalist
                                },
                                domProps: { value: _vm.newRow[header.name] },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.saveNewRow()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.newRow,
                                      header.name,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-nowrap" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-admin",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.saveNewRow()
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-plus" })]
                    )
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.rowsFiltered, function(row, index) {
                return _c(
                  "tr",
                  { key: "row--" + index, class: { loading: row.__loading } },
                  [
                    _c("td", { staticClass: "px-1 text-center" }, [
                      _vm._v(_vm._s(index))
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.headers, function(header, colIndex) {
                      return _c(
                        "td",
                        { key: "row-" + index + "-col-" + colIndex },
                        [
                          header.id
                            ? [_vm._v(_vm._s(row[header.name]))]
                            : header.type == "boolean"
                            ? [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row[header.name],
                                      expression: "row[header.name]"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(row[header.name])
                                      ? _vm._i(row[header.name], null) > -1
                                      : row[header.name]
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = row[header.name],
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                row,
                                                header.name,
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                row,
                                                header.name,
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(row, header.name, $$c)
                                        }
                                      },
                                      function($event) {
                                        return _vm.saveRow(row)
                                      }
                                    ]
                                  }
                                })
                              ]
                            : header.type == "number"
                            ? [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row[header.name],
                                      expression: "row[header.name]"
                                    }
                                  ],
                                  staticClass: "admin-input text-right",
                                  attrs: {
                                    type: "text",
                                    placeholder: header.name
                                  },
                                  domProps: { value: row[header.name] },
                                  on: {
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.saveRow(row)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        row,
                                        header.name,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            : [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row[header.name],
                                      expression: "row[header.name]"
                                    }
                                  ],
                                  staticClass: "admin-input",
                                  attrs: {
                                    type: "text",
                                    placeholder: header.name,
                                    list: header.datalist
                                  },
                                  domProps: { value: row[header.name] },
                                  on: {
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.saveRow(row)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        row,
                                        header.name,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-nowrap" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-xs btn-admin",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.saveRow(row)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-fw",
                            class: row.__loading ? "fa-refresh" : "fa-save"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-xs btn-admin",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeRow(row)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-fw",
                            class: row.__loading ? "fa-refresh" : "fa-trash"
                          })
                        ]
                      )
                    ])
                  ],
                  2
                )
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "fa fa-fw fa-search" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }