//
//

import Vue from 'vue';
import cloneDeep from 'lodash.clonedeep';
import MenuEditorRow from './BarMenuEditorRow.vue';
import XlsTable from './XlsTable.vue';
import SimpleEditor from './SimpleEditor.vue';

export default {
  //=====================================================
  name: 'BarMenuEditor',
  //=====================================================
  components: { 
    MenuEditorRow, XlsTable, SimpleEditor
  },
  //=====================================================
  data() {
    return {
      tab: 'simple',
      allowedTabs: ['tree', 'excel', 'simple'],
      loading: true,
      info: {
        message: null,
        icon: null,
        type: null
      },
      dataset: {
        title: 'Loading...',
        menu: []
      },
      newCategory: 'Nowa kategoria',
      search: '',
      html: null,
      columns: [
        { name: 'id', type: 'number', width: 50, readonly: true },
        { name: 'lp', type: 'number', width: 50 },
        { name: 'kategoria', width: 150, datalist: 'datalist-kategorie' },
        { name: 'nazwa', width: 150 },
        { name: 'opis', width: 150 },
        { name: 'cena', type: 'number', width: 50 },
        { name: 'aktywne', type: 'boolean', width: 50 },
      ]
    }
  },
  //=====================================================
  computed: {
    //===================
    categories() {
      if (!this.dataset) return [];
      if (!this.dataset.menu) return [];

      return Object.keys(this.dataset.menu);
    },
    //===================
    allRows() {
      // console.log('[menu-editor][allRows] this.dataset:', this.dataset);
      let rows = [];

      if (!this.dataset || !this.dataset.menu) {
        return [];
      }

      Object.values(this.dataset.menu).forEach(kategoria => {
        kategoria.pozycje.forEach(pozycja => {
          rows.push(pozycja);
        })
      });

      // console.log('[menu-editor][allRows] rows:', rows);
      return rows;
    },
    //===================
    filteredMenu() {
      let menu = {};
      let items = Object.values(this.dataset.menu);

      items = items.filter(k => {
        return `${k.nazwa}`.toLowerCase().indexOf(`${this.search}`.toLowerCase()) != -1
      })
      .sort((a, b) => {
        return a.lp > b.lp ? 1 : -1
      })
      // console.log('[menu-editor][filteredMenu] items:', items);

      items.forEach(i => {
        let kat = i.nazwa;
        Vue.set(menu, kat, i);
      })

      return menu;
    },
    //===================
    error: {
      get() {
        return this.info.message;
      },
      set(value) {
        this.info.message = value;
        this.info.icon = 'warning';
        this.info.type = 'danger';
      }
    },
    //===================
    message: {
      get() {
        return this.info.message;
      },
      set(value) {
        this.info.message = value;
        this.info.icon = 'check';
        this.info.type = 'success';
      }
    }
    //===================
  },
  //=====================================================
  methods: {
    //===================
    toggleOpenState(kategoria) {
      // console.log('[menu-editor][toggleOpenState] kategoria:', kategoria);
      let key = kategoria.nazwa;
      kategoria.__open = !kategoria.__open;
      this.setOpenState(key, kategoria.__open);
    },
    //===================
    setOpenState(key, value = false) {
      // console.log('[menu-editor][setOpenState] key:', key);
      return localStorage.setItem(`isOpen.${key}`, value)
    },
    //===================
    getOpenState(key) {
      // console.log('[menu-editor][getOpenState] key:', key);
      let res = false;

      try {
        res = JSON.parse(localStorage.getItem(`isOpen.${key}`));
      } catch(_) {
        res = false;
      }

      return res;
    },
    //===================
    processData(data) {
      // console.log('[menu-editor][processData] data:', data);
        Object.keys(data.menu)
        .filter(kat => (typeof data.menu[kat] == 'object'))
        .forEach(kat => {
          Object.keys(data.menu[kat]).forEach(k => {
            let kategoria = data.menu[kat];

            // console.log(kat, k);
            if (typeof kategoria[k] != 'object') {
              Vue.set(kategoria, `_${k}`, false);
              Vue.set(kategoria, `__loading`, false);
              Vue.set(kategoria, `__changed`, false);
              Vue.set(kategoria, `__open`, this.getOpenState(kat));

            } else if (Array.isArray(kategoria[k])) {
              let pozycje = kategoria[k];
              // console.log(kat, 'Pozycje', pozycje);
              pozycje.forEach(p => {
                Object.keys(p)
                .filter(key => (typeof key == 'string'))
                .forEach(key => {
                  // console.log(kat, 'Pozycja', p, key);
                  Vue.set(p, `_${key}`, false);
                  Vue.set(p, `__loading`, false);
                  Vue.set(p, `__changed`, false);
                })
              });
            }
          })
        });

      // console.log('[menu-editor][processData] normalized data:', data);
      return data;
    },
    //===================
    getMenu() {
      // console.log('[menu-editor][getMenu]');
      return this.$axios.get('/api/bar-menu-editor/index')
      .then(data => {
        if (!data || !data.menu) {
          this.info.icon = 'warning';
          this.info.type = 'warning';
          this.info.message = 'Brak odpowiedzi serwera';
          return
        }
        // console.log('[menu-editor][getMenu] data:', data);
        this.dataset = this.processData(data);
      });
    },
    //===================
    refresh() {
      // console.log('[menu-editor][refresh]');
      this.loading = true;
      this.getMenu()
      .then(() => {
        this.loading = false;
      })
    },
    //===================
    openCategoryField(kategoria, field) {
      if (kategoria.__loading) {
        return
      }
      Vue.set(kategoria, `_${field}`, true);
    },
    //===================
    closeCategoryField(kategoria, field) {
      Vue.set(kategoria, `_${field}`, false);
    },
    //===================
    closeCategory(kategoria) {
      this.closeItem(kategoria);
    },
    //===================
    addCategory() {
      let kategoria = {
        id: -1 * (Object.keys(this.dataset.menu).length + 1),
        nazwa: this.newCategory,
        lp: 0,
        kolor: '',
        html: '',
        opis_kategorii: 'opis',
        aktywne: 1,
        pozycje: [],
        __loading: false,
        __open: true,
      };

      Vue.set(this.dataset.menu, this.newCategory, kategoria);

    },
    //===================
    toggleCategoryState(kategoria) {
      kategoria.aktywne = !kategoria.aktywne;
      this.saveCategory(kategoria);
    },
    //===================
    saveCategory(kategoria) {
      // console.log('[menu-editor][saveCategory] kategoria:', kategoria);
      this.closeCategory(kategoria);
      Vue.set(kategoria, '__loading', true);

      let payload = cloneDeep(kategoria);
      payload.pozycje = null;

      this.$axios.post(`/api/bar-menu-editor/save/${kategoria.id}`, { kategoria: payload })
      .then(response => {
        // console.log('[menu-editor][saveCategory] response:', response);
        if (kategoria.id < 0 && response.data.id) {
          Vue.set(kategoria, 'id', response.data.id);
        }
        Vue.set(kategoria, '__loading', false);
        this.message = response.status;
      })
      .catch(e => {
        this.error = e && e.message ? e.message : 'Nieznany błąd';
        Vue.set(kategoria, '__loading', false);
      })
    },
    //===================
    removeCategory(kategoria) {
      // console.log('[menu-editor][removeCategory] kategoria:', kategoria);
      if (confirm(`Czy na pewno chcesz kategorię "${kategoria.nazwa}"?`) == false) {
        return
      }

      this.closeCategory(kategoria);
      Vue.set(kategoria, '__loading', true);

      let payload = cloneDeep(kategoria);
      payload.pozycje = null;

      if (kategoria.id <= 0) {
        Vue.delete(this.dataset.menu, kategoria.nazwa);
        return;
      }

      this.$axios.post(`/api/bar-menu-editor/remove/${kategoria.id}`, { kategoria: payload })
      .then(response => {
        // console.log('[menu-editor][removeCategory] response:', response);
        Vue.set(kategoria, '__loading', false);
        this.message = response.status;
        if (response.data && response.data.removed) {
          Vue.delete(this.dataset.menu, kategoria.nazwa);
        }
      })
      .catch(e => {
        this.error = e && e.message ? e.message : 'Nieznany błąd';
        Vue.set(kategoria, '__loading', false);
      })
    },
    //===================
    closeItem(pozycja) {
      Object.keys(pozycja)
      .filter(k => k.startsWith('_') && !k.startsWith('__'))
      .forEach(k => {
        Vue.set(pozycja, k, false)
      })
    },
    //===================
    addItem(kategoria) {
      // console.log('[menu-editor][addItem] kategoria:', kategoria);
      let pozycje = kategoria.pozycje || [];
      let pozycja = {
        id: -1 * ((kategoria.pozycje || []).length + 1),
        lp: 10,
        nazwa: 'Nowy',
        opis: 'Opis',
        cena: 0,
        aktywne: 1,
        img: null,
        kategoria: kategoria.nazwa,
        nazwa_kategorii: kategoria.nazwa || 'DOMYŚLNA'
      };
      // console.log('[menu-editor][addItem] pozycja:', pozycja);
      pozycje.unshift(pozycja);
    },
    //===================
    saveItem(pozycja, kategoria = {}) {
      // console.log('[menu-editor][saveItem] pozycja:', pozycja, '| kategoria:', kategoria);
      Vue.set(pozycja, '__loading', true);
      this.closeItem(pozycja);

      pozycja.kategoria = kategoria.nazwa || pozycja.kategoria;
      pozycja.nazwa_kategorii = kategoria.nazwa || pozycja.nazwa_kategorii;

      this.$axios.post(`/api/bar-menu-editor/save/${pozycja.id}`, { pozycja })
      .then(response => {
        // console.log('[menu-editor][saveItem] response:', response);
        if (pozycja.id < 0 && response.data.id) {
          Vue.set(pozycja, 'id', response.data.id);
        }
        Vue.set(pozycja, '__loading', false);
        this.message = response.status;
      })
      .catch(e => {
        this.error = e && e.message ? e.message : 'Nieznany błąd';
        Vue.set(pozycja, '__loading', false);
      })
    },
    //===================
    removeItem(pozycja, kategoria = {}) {
      // console.log('[menu-editor][removeItem] pozycja:', pozycja, '| kategoria:', kategoria);
      Vue.set(pozycja, '__loading', true);
      this.closeItem(pozycja);

      kategoria.pozycje = kategoria.pozycje || [];

      if (pozycja.id <= 0) {
        let index = kategoria.pozycje.findIndex(p => p == pozycja);
        index >=0 ? kategoria.pozycje.splice(index,1) : null;
        return;
      }

      pozycja.kategoria = kategoria.nazwa || pozycja.kategori;
      pozycja.nazwa_kategorii = kategoria.nazwa || pozycja.nazwa_kategorii;

      this.$axios.post(`/api/bar-menu-editor/remove/${pozycja.id}`, { pozycja })
      .then(response => {
        // console.log('[menu-editor][removeItem] response:', response);
        Vue.set(pozycja, '__loading', false);
        this.message = response.status;
        if (response.data && response.data.removed) {
          let index = kategoria.pozycje.findIndex(p => p == pozycja);
          if (index >= 0) {
            kategoria.pozycje.splice(index,1);
          } else {
            this.refresh();
          }
        }
      })
      .catch(e => {
        this.error = e && e.message ? e.message : 'Nieznany błąd';
        Vue.set(pozycja, '__loading', false);
      })
    },
    //===================
  },
  //=====================================================
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    console.log('[urlParams]', urlParams);
    let tab = urlParams.get('tab');
    if (this.allowedTabs.includes(tab)) {
      this.tab = tab;
    }
  },
  //=====================================================
  mounted() {
    this.refresh()
  }
  //=====================================================
}
