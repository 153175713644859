//
//
//
//
//
//
//

export default {
  //=================================================
  name: 'v-input',
  //=================================================
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    datalist: String
  },
  //=================================================
  computed: {
    //===================
    local_value: {
      get() { return this.value },
      set(value) {
        if (this.disabled) {
          return
        }

        if (this.type == 'number' && typeof value == 'string') {
          value = value.replace(/[^0-9.,]/g, '')
        }

        this.$emit('input', value)
      }
    }
    //===================
  },
  //=================================================
  methods: {
    //===================
    toggle() {
      this.local_value = !this.local_value;
    },
    //===================
  },
  //=================================================
}
