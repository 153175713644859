//
//

import Vue from 'vue';

export default {
  //=====================================================
  name: 'BarMenuEditorRow',
  //=====================================================
  props: {
    pozycja: Object,
    kategoria: Object,
    hidden: {
      type: [Boolean, Number],
      default: 0
    }
  },
  //=====================================================
  data() {
    return {
      defaults: {}
    }
  },
  //=====================================================
  computed: {
    //===================
    local: {
      get() {
        return this.pozycja
      },
      set(value) {
        console.log('[menu-editor-row][set] value:', value);
        this.$emit('update:pozycja', value)
      }
    },
    //===================
  },
  //=====================================================
  methods: {
    //===================
    open(field) {
      if (this.pozycja.__loading) {
        return
      }
      Vue.set(this.local, `_${field}`, true);
    },
    //===================
    save(field) {
      console.log('[menu-editor-row][save] field:', field);
      Vue.set(this.local, `_${field}`, false);
      this.$emit('save', this.pozycja, this.kategoria);
    },
    //===================
    remove() {
      if (confirm(`Czy na pewno chcesz usunąć pozycję "${this.pozycja.nazwa}"?`)) {
        this.$emit('remove', this.pozycja, this.kategoria);
      }
    },
    //===================
    reset(field) {
      console.log('[menu-editor-row][reset] field:', field);
      Vue.set(this.local, `${field}`, this.defaults[field]);
      Vue.set(this.local, `_${field}`, false);
    },
    //===================
    exit(field) {
      console.log('[menu-editor-row][exit] field:', field);
      Vue.set(this.local, `_${field}`, false);
    },
    //===================
    toggleState() {
      this.local.aktywne = !this.local.aktywne;
      this.save();
    },
    //===================
  },
  //=====================================================
  mounted() {
    this.defaults = Object.assign({}, this.local);
  },
  //=====================================================
}
