import axios from 'axios';

const logMessages = (data) => {
  window.$app.$store.messages.push(...data.log || []);
  return data;
}

const logErrors = (e) => {
  window.$app.$store.messages.push({ type: 'danger', message: e.message });
  console.log('[axios] error:', e);
  return e
}

const $axios = {
  get() {
    return axios.get(...arguments)
    .then(reposne => reposne.data)
    .then(data => logMessages(data))
    .catch(e => logErrors(e))
  },
  post() {
    return axios.post(...arguments)
    .then(reposne => reposne.data)
    .then(data => logMessages(data))
    .catch(e => logErrors(e))
  }
};


export default $axios;
